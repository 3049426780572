document.onreadystatechange = function () {
  const el = document.querySelector('.left__avatar')

  let ori_cls = el.className
  el.addEventListener('transitionend', (e) => {
    el.className = ori_cls
  })
  el.addEventListener('click', () => {
    el.className += ' ts-rotate-360'
  })

  const left = document.getElementById("eye-left")
  const right = document.getElementById("eye-right")

  const moveEye = (eye, mouseXY) => {
    const rect = left.getBoundingClientRect()
    const { x, y } = mouseXY

    const relative = { x: x - rect.left, y: y - rect.top }
    const len = Math.sqrt(relative.x ** 2 + relative.y ** 2)
    const relativeUnit = { x: relative.x / len, y: relative.y / len}

    eye.style.left = `${(relativeUnit.x || 0) * 2}px`
    eye.style.top = `${(relativeUnit.y || 0) * 2}px`
  }

  document.onmousemove = function (e) {
    // clientXY
    const { x, y } = e
    moveEye(left, {x, y})
    moveEye(right, {x, y})
  }

  const chatEle = document.getElementById('bot-chat')
  document.getElementById('bot').addEventListener('click', (e) => {
    chatEle.classList.remove('hidden')
  })
  document.getElementById('btn-close').addEventListener('click', (e) => {
    chatEle.classList.add('hidden')
  })
}
